import { render, staticRenderFns } from "./NewsletterConfirmationModal.vue?vue&type=template&id=ff5527dc&scoped=true&"
import script from "./NewsletterConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./NewsletterConfirmationModal.vue?vue&type=script&lang=js&"
import style0 from "./NewsletterConfirmationModal.vue?vue&type=style&index=0&id=ff5527dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff5527dc",
  null
  
)

export default component.exports